import React, { FC, memo, ReactElement, useCallback, useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import {
    ActionButtonProps,
    AdProps,
    ComponentType,
    DashboardPresentingProps,
    LabelProps,
    LayoutComponentProps,
    StreamTeaserProps,
    TagProps,
    TeaserProps,
} from '@sport1/types/web'
import {
    ResponsiveSpacing,
    SpacingsVariants,
    SystemSpacingsProps,
} from '@sport1/news-styleguide/Types'
import AdSlot from '../Ads/AdSlot'
import { DisplaySize } from '../../hooks/useBreakpointDisplaySize'
import DynamicTeaserDisplay from './DynamicTeaserDisplay'
import { adjustTeasers } from './utils'
import ActionButtons from '@/components/ActionButtons'
import Card from '@/components/Card'
import DashboardPresenting from '@/components/DashboardPresenting'
import StreamTeaser from '@/components/StreamTeaser'
import TeamPageCTAButton from '@/components/TeamPageCTAButton'
import Teaser from '@/components/Teaser'
import RightColumn from '@/components/TeaserCard/RightColumn'
import HorizontalCardContent from '@/components/TeaserCard/horizontal'
import ThreeItemCardContent from '@/components/TeaserCard/three'
import TwoItemCardContent from '@/components/TeaserCard/two'
import { isCorrectSize } from '@/context/AdPlacementContext/AdPlacementProvider.utils'
import renderSideColumnComponent from '@/helpers/renderSideColumnComponent'
import { useFindValueInNavigation, useIsOnTeamPage } from '@/helpers/teamHelper'
import { SizeTypes } from '@/types/size'
import { TeaserSizeTypes } from '@/types/teaser'
import reorderArray from '@/utils/array/reorderArray'
import Breakpoint from '@/utils/breakpoints/Breakpoint'
import { createPlaylistHref } from '@/utils/navigation/Navigator'
import { MediaQueryContext } from '@/utils/breakpoints/MediaQuery'
import renderBaseChannelComponent from '@/helpers/renderBaseChannelComponent'

/**
 * SPORT1 REFRESH TODO: Potential clean up, correct code mistakes, prettify - was a quick setup before the presentation (after going live needed improvements)
 */

export type CardProps = SystemSpacingsProps & {
    backgroundColor?: 'pearl' | 'onyx'
    title?: string
    teaser: (TeaserProps | AdProps)[]
    rightColumnComponents?: LayoutComponentProps[]
    actionButtons?: ActionButtonProps[]
    horizontal?: boolean
    tag?: TagProps | LabelProps
    testID?: string
    dashboardPresenting?: DashboardPresentingProps[]
    lazy?: boolean
    horizontalScrollbarEnabled?: boolean
    arrowMargin?: keyof SpacingsVariants
    spaceX?: ResponsiveSpacing
    hintHref?: string
    hintText?: string
    teaserType?: ComponentType
}

type GetTeaserComponentProps = {
    index: number
    type: TeaserSizeTypes
    includeAdsForSizes: DisplaySize
    isCardTeaser?: boolean
    noExpand?: boolean
    headlineNumberOfLines?: number
    verticalExpand?: boolean
}

export type ItemCardProps = {
    title?: string
    lazy?: boolean
    hasRightColumn?: boolean
    displaySizes?: DisplaySize
    teaser: (TeaserProps | AdProps)[]
    backgroundColor?: 'TRANSPARENT' | 'onyx' | 'pearl'
    getTeaserComponent: (props: GetTeaserComponentProps) => ReactElement
    horizontalScrollbarEnabled?: boolean
    arrowMargin?: keyof SpacingsVariants
    spaceX?: ResponsiveSpacing
}

const TeaserCard: FC<CardProps> = ({
    backgroundColor,
    teaser: unfilteredTeasers,
    title,
    rightColumnComponents,
    horizontal,
    actionButtons,
    tag,
    marginBottom,
    testID,
    dashboardPresenting,
    lazy,
    horizontalScrollbarEnabled,
    arrowMargin,
    spaceX,
    hintHref,
    hintText,
    teaserType,
}) => {
    const isOnTeamPage = useIsOnTeamPage()
    const gamePlanButtonUrl = useFindValueInNavigation('web-team-news')
    const { asPath } = useRouter()
    const { currentDevice } = useContext(MediaQueryContext)

    const titleHref =
        useMemo(() => {
            if (asPath.startsWith('/tv-video') || tag?.url?.includes('/layoutMode/VIDEO')) {
                return createPlaylistHref(tag)
            }
        }, [asPath, tag]) ?? hintHref

    const hasRightColumn = rightColumnComponents && rightColumnComponents.length > 0

    const teaser = useMemo(
        () => unfilteredTeasers.filter(item => isCorrectSize(item, currentDevice === 'mobile')),
        [currentDevice, unfilteredTeasers]
    )

    const teaserWithoutEditorialStreamTeaser = useMemo(
        () => teaser.filter(item => item.type !== ComponentType.EDITORIAL_STREAM_TEASER),
        [teaser]
    )

    const getTeaser = useCallback(
        (props: GetTeaserComponentProps) => {
            const teaserEntry = horizontal
                ? teaserWithoutEditorialStreamTeaser[props.index]
                : teaser[props.index]

            if (teaserEntry.type === ComponentType.AD) {
                //ad is only displayed for the active DisplaySize to prevent duplicate div ids
                if (
                    (currentDevice === SizeTypes.MOBILE && props.includeAdsForSizes.isMobile) ||
                    (currentDevice === SizeTypes.TABLET && props.includeAdsForSizes.isTablet) ||
                    (currentDevice === SizeTypes.DESKTOP && props.includeAdsForSizes.isDesktop)
                ) {
                    return <AdSlot {...teaserEntry} />
                }
                return <React.Fragment />
            } else if (
                teaserEntry.type !== ComponentType.EDITORIAL_STREAM_TEASER ||
                props.index !== 0
            )
                return (
                    <Teaser
                        backgroundColor={backgroundColor}
                        isCardTeaser={props.isCardTeaser}
                        size={props.type}
                        teaser={teaserEntry}
                        expand={!props.noExpand}
                        headlineNumberOfLines={props.headlineNumberOfLines}
                        verticalExpand={props.verticalExpand}
                    />
                )
            // null is not possible because GridLayout don't accept null components
            return <React.Fragment />
        },
        [backgroundColor, teaser, horizontal, teaserWithoutEditorialStreamTeaser, currentDevice]
    )

    const content = useMemo((): ReactElement | undefined => {
        const displaySizes = {
            isMobile: currentDevice === SizeTypes.MOBILE,
            isTablet: currentDevice === SizeTypes.TABLET,
            isDesktop: currentDevice === SizeTypes.DESKTOP,
        }
        if (horizontal) {
            return (
                <HorizontalCardContent
                    title={title}
                    teaser={teaserWithoutEditorialStreamTeaser}
                    backgroundColor="TRANSPARENT"
                    getTeaserComponent={getTeaser}
                    horizontalScrollbarEnabled={horizontalScrollbarEnabled}
                    arrowMargin={arrowMargin}
                    lazy={lazy}
                    spaceX={spaceX}
                />
            )
        }
        const adjustedTeasers =
            currentDevice === SizeTypes.MOBILE
                ? teaser
                : adjustTeasers(teaser, hasRightColumn, currentDevice)
        switch (adjustedTeasers.length) {
            case 2:
                return (
                    <TwoItemCardContent
                        {...{
                            title,
                            lazy,
                            teaser: adjustedTeasers,
                            hasRightColumn,
                            displaySizes,
                        }}
                        getTeaserComponent={getTeaser}
                        horizontalScrollbarEnabled={horizontalScrollbarEnabled}
                    />
                )
            case 3:
                return (
                    <ThreeItemCardContent
                        {...{
                            title,
                            lazy,
                            teaser: adjustedTeasers,
                            hasRightColumn,
                            displaySizes,
                        }}
                        getTeaserComponent={getTeaser}
                        horizontalScrollbarEnabled={horizontalScrollbarEnabled}
                    />
                )
            default:
                return (
                    <DynamicTeaserDisplay
                        {...{
                            title,
                            lazy,
                            teaser: adjustedTeasers,
                            hasRightColumn,
                            displaySizes,
                        }}
                        getTeaserComponent={getTeaser}
                        horizontalScrollbarEnabled={horizontalScrollbarEnabled}
                    />
                )
        }
    }, [
        horizontal,
        teaser,
        title,
        getTeaser,
        horizontalScrollbarEnabled,
        arrowMargin,
        lazy,
        spaceX,
        hasRightColumn,
        teaserWithoutEditorialStreamTeaser,
        currentDevice,
    ])

    if (teaser?.length === 0) {
        return null
    }

    const [firstTeaser] = teaser

    return (
        <NonFlexingContainer
            id={title === 'WEITERE TOP THEMEN' ? 'piano_weitere-top-themen' : undefined}
        >
            <Card
                backgroundColor={backgroundColor}
                title={title}
                titleHref={titleHref}
                tag={tag}
                showHintText={horizontal && true}
                marginBottom={marginBottom}
                testID={testID}
                hint={hintText}
            >
                {dashboardPresenting && dashboardPresenting?.length > 0
                    ? dashboardPresenting.map((item, index) => {
                          return (
                              <DashboardPresenting
                                  key={`${item.name}-${index}`}
                                  dashboardPresenting={item}
                                  marginBottom="spacing-6"
                              />
                          )
                      })
                    : null}

                <NonFlexingContainer horizontal>
                    <FlexingContainer className="s1-teaser-card-content" testID="card-content">
                        {firstTeaser.type === ComponentType.EDITORIAL_STREAM_TEASER ? (
                            <StreamTeaser
                                isCardTeaser
                                componentData={firstTeaser as StreamTeaserProps}
                                shouldAddMarginBottom={horizontal}
                            />
                        ) : null}
                        {content}

                        {isOnTeamPage && teaserType === ComponentType.TEAM_TEASER_CARD ? (
                            <TeamPageCTAButton
                                buttonText="Alle News"
                                ctaButtonUrl={gamePlanButtonUrl}
                                testID={testID}
                            />
                        ) : null}
                        {actionButtons && !isOnTeamPage && (
                            <ActionButtons
                                actionButtons={actionButtons}
                                buttonVariant="highlight"
                                marginTop="spacing-6"
                                backgroundColor={backgroundColor}
                            />
                        )}
                    </FlexingContainer>
                    {hasRightColumn ? (
                        <Breakpoint lazy={lazy} device={SizeTypes.DESKTOP}>
                            <NonFlexingContainer
                                display={['none', 'none', 'none', 'flex']}
                                width={351}
                                paddingLeft="spacing-6"
                                testID="teaser-card-desktop-right-column-container"
                            >
                                <RightColumn
                                    components={reorderArray({
                                        elements: rightColumnComponents,
                                        sourceIndex: rightColumnComponents.indexOf(
                                            rightColumnComponents.filter(
                                                comp => comp.type === ComponentType.AD
                                            )[0]
                                        ),
                                        destinationIndex: rightColumnComponents.length - 1,
                                    })}
                                    renderAdsOnDevice={[SizeTypes.DESKTOP]}
                                    renderElement={renderSideColumnComponent}
                                />
                            </NonFlexingContainer>
                        </Breakpoint>
                    ) : null}
                </NonFlexingContainer>
            </Card>
            {/*
                The Right Column on mobile and tablet isn't part of the TeaserCard,
                however, it's treated as so to avoid having the implementation between
                different devices (mobile-tablet, desktop) in different places
            **/}
            {hasRightColumn ? (
                <Breakpoint lazy={lazy} device={[SizeTypes.MOBILE, SizeTypes.TABLET]}>
                    <NonFlexingContainer
                        display={['flex', 'flex', 'flex', 'none']}
                        width="100%"
                        testID="teaser-card-mobile-right-column-container"
                    >
                        <RightColumn
                            components={rightColumnComponents}
                            renderAdsOnDevice={[SizeTypes.MOBILE, SizeTypes.TABLET]}
                            renderElement={renderBaseChannelComponent}
                        />
                    </NonFlexingContainer>
                </Breakpoint>
            ) : null}
        </NonFlexingContainer>
    )
}

export default memo(TeaserCard)
